import {Container} from "@mui/material";
import Image from "next/image";

export default function ExpiredPix() {
  return (
    <Container
      sx={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Image alt="expired" height={300} src="/expired.png" width={300} />
    </Container>
  );
}

import { reportError, throwUnlessSucccess } from "../../lib/sentry";

async function checkStatus(pixId: string) {
  try {
    const response = await fetch(`/api/pix/checkPixStatus?pixId=${pixId}`);
    throwUnlessSucccess(response);
    if (response) {
      return await response.json();
    } else {
      return undefined;
    }
  } catch (error: unknown) {
    reportError(error as Error);
    return undefined;
  }
}

async function checkPixPaymentStatus(pixId: string, statusToIgnore: number) {
  const data = await checkWhileAlive(pixId, statusToIgnore);
  if (data) return data.status;
}

function checkWhileAlive(
  pixId: string,
  statusToIgnore: number
): Promise<any | null> {
  return new Promise((resolve) => {
    let times = 0;
    let timerId = setInterval(check, 1000);
    async function check() {
      if (times >= 600) {
        clearInterval(timerId);
        resolve(null);
      } else {
        times += 1;
        const data = await checkStatus(pixId);
        if (data.status != null && data.status != statusToIgnore) {
          resolve(data);
          clearInterval(timerId);
        }
      }
    }
  });
}

export default { checkPixPaymentStatus };

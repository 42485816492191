import {reportError, throwUnlessSucccess} from "../sentry";

import {getExchangeRates} from "./exchangeRateService";

interface QuoteParams {
  userEmail: string;
  amount: number;
  currency: string;
  comment: string;
  interfaceId: number;
}

interface Rates {
  quoteRate: number;
  usdtBrlRate: number;
}

interface Uuid {
  uuid: string;
}

const onSiteInterfaceId = 1;

async function handleQuotation(
  userEmail: string,
  amount: number,
  currency: string,
  comment: string,
  interfaceId: number,
  uuid: string,
) {
  const data: QuoteParams = {
    userEmail: userEmail,
    amount: amount,
    currency: currency,
    comment: comment,
    interfaceId: interfaceId,
  };

  try {
    let quoteResponse = undefined;

    if (interfaceId == onSiteInterfaceId) {
      quoteResponse = await saveOnSiteQuote({
        ...data,
      });
    } else {
      const rates = await getExchangeRates(currency, amount);

      const usdtBrlRate = rates.BRL_TO_PAY / rates.value_pxusdt;
      
      const quoteRate =
      currency === "BRL" ? 1 / usdtBrlRate : currency === "ARS" ? 1 / rates.USDT_ARS : 1;

      quoteResponse = await saveRemoteQuote({
        ...data,
        quoteRate,
        usdtBrlRate,
        uuid,
      });
    }

    return await quoteResponse?.json();
  } catch (error: unknown) {
    reportError(error as Error);
  }
}

async function saveOnSiteQuote(data: QuoteParams) {
  try {
    const response = await fetch("/api/quote/saveOnSiteQuote", {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    throwUnlessSucccess(response);

    return response;
  } catch (error: unknown) {
    reportError(error as Error);

    return undefined;
  }
}

async function saveRemoteQuote(data: QuoteParams & Rates & Uuid) {
  try {
    const response = await fetch(`${process.env.NEXT_PUBLIC_HOST}/api/quote/saveRemoteQuote`, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    throwUnlessSucccess(response);

    return response;
  } catch (error: unknown) {
    reportError(error as Error);

    return undefined;
  }
}

async function getRemoteQuoteData(uuid: string) {
  try {
    const response = await fetch(`/api/quote/getRemoteQuoteData?uuid=${uuid}`);

    throwUnlessSucccess(response);

    return await response.json();
  } catch (error: unknown) {
    reportError(error as Error);

    return undefined;
  }
}

async function isQuotePaid(quoteId: number) {
  try {
    const response = await fetch(`/api/quote/getQuotePayment?quoteId=${quoteId}`);

    throwUnlessSucccess(response);
    const result = await response.json();

    return result !== null;
  } catch (error: unknown) {
    reportError(error as Error);

    return undefined;
  }
}

async function getQuoteCreator(merchantId: number, userId: number) {
  try {
    const response = await fetch(
      `/api/quote/getQuoteCreator?merchantId=${merchantId}&userId=${userId}`,
    );

    throwUnlessSucccess(response);

    return await response.json();
  } catch (error: unknown) {
    reportError(error as Error);

    return undefined;
  }
}

export default {
  handleQuotation,
  getRemoteQuoteData,
  getQuoteCreator,
  isQuotePaid,
};

import {reportError, throwUnlessSucccess} from "../sentry";

export async function getExchangeRates(currency: string, amount: number) {
  try {
    const response = await fetch(
      `${process.env.NEXT_PUBLIC_HOST}/api/oracle/getExchangeRate`,
      {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({currency, amount}),
      },
    );

    throwUnlessSucccess(response);
    const data = await response.json();

    return data;
  } catch (error) {
    reportError(error as Error);

    return undefined;
  }
}
import _ from "lodash";

import {reportError, throwUnlessSucccess} from "../../lib/sentry";
import {getExchangeRates} from "./exchangeRateService";

interface QrParams {
  quoteId: number;
  pixId: string;
  brCode: string;
  pixSecAlive: number;
  amount: number;
}

interface Rates {
  quoteRate: number;
  usdtBrlRate: number;
}

const pixSecAlive = 300;

async function handleQrCreation(
  quoteId: number,
  amount: number,
  chargeAmount: number,
  currency: string,
  userEmail: string,
) {
  const rates = await getExchangeRates(currency, chargeAmount);

  const usdtBrlRate = rates.BRL_TO_PAY / rates.value_pxusdt;

  const quoteRate =
    currency === "BRL" ? 1 / usdtBrlRate : currency === "ARS" ? 1 / rates.USDT_ARS : 1;

  const qrData = await generateQR(amount, pixSecAlive, userEmail);

  if (qrData) {
    const swapixData: QrParams = {
      pixId: qrData.operation.operation_id,
      quoteId: quoteId,
      brCode: qrData.emv,
      pixSecAlive: pixSecAlive,
      amount: qrData.operation.rate.total_brl,
    };

    saveQrData({...swapixData, quoteRate, usdtBrlRate});

    return [qrData.emv as string, qrData.operation.operation_id as string, qrData];
  } else {
    return undefined;
  }
}

async function generateQR(amount: number, pixSecAlive: number, userEmail: string) {
  return generateQRwithSwapix(amount, pixSecAlive, userEmail);
}

export interface SwapixQrParams {
  amount: number;
  pixSecAlive: number;
  userEmail: string;
}

async function generateQRwithSwapix(amount: number, pixSecAlive: number, userEmail: string) {
  try {
    const fixedAmount = parseFloat(amount.toFixed(2));

    const data: SwapixQrParams = {amount: fixedAmount, pixSecAlive, userEmail};

    const response = await fetch(`${process.env.NEXT_PUBLIC_HOST}/api/pix/sendPixCharge`, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    throwUnlessSucccess(response);

    return await response.json();
  } catch (error: unknown) {
    reportError(error as Error);

    return undefined;
  }
}

async function saveQrData(data: QrParams & Rates) {
  try {
    const response = await fetch(`${process.env.NEXT_PUBLIC_HOST}/api/pix/saveQrData`, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    throwUnlessSucccess(response);

    return response;
  } catch (error: unknown) {
    {
      reportError(error as Error);

      return undefined;
    }
  }
}

async function getQuotePixData(quoteId: number) {
  try {
    const response = await fetch(`/api/pix/getQuotePixData?quoteId=${quoteId}`);

    throwUnlessSucccess(response);
    const qrs = await response.json();

    if (_.size(qrs) != 0) {
      return qrs[0];
    } else {
      return null;
    }
  } catch (error: unknown) {
    reportError(error as Error);

    return undefined;
  }
}

export default {handleQrCreation, getQuotePixData};
